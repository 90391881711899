import React from "react";
import PropTypes from "prop-types";

import Baffle from "baffle-react";

function Glitch({ text, isVisible }) {

    return (
        <div className="glitch" data-text={text}>
            <Baffle
                speed={50}
                obfuscate={!isVisible}
                update={isVisible}
                revealDuration={1000}
                revealDelay={1000}
            >
                { text }
            </Baffle>
        </div>
    );
}

Glitch.propTypes = {
    text: PropTypes.string,
}

Glitch.defaultProps = {
    text: "",
}

export default Glitch;
