import React from "react";
import { animated, useSpring, to as interpolate, config } from "react-spring";
import { isDesktop } from "react-device-detect";
import { Rating } from "@mui/material";

export default function Card({
                                 i, x, y, rot, scale, trans, data,
                                 zIndex, delay,
                                 appear, fade,
                                 desktopBind, mobileBind,
                                 isVisible, cursor,
}) {

    const { image, title, number, rating, description } = data;
    const styles = useSpring({
        from: isVisible ? fade(i) : appear(i),
        to: isVisible ? appear(i) : fade(i),
        delay: delay ? delay : i * 100,
        zIndex,
        config: config.wobbly,
    });

    return (
        <animated.div className="card" key={i}
                      style={{
                          ...styles,
                          zIndex: zIndex ? zIndex : undefined,
                          transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
                      }}
        >
            <animated.div
                {...(isDesktop ? desktopBind(i) : mobileBind(i))}
                style={{
                    transform: interpolate([rot, scale], trans),
                    cursor: cursor,
                }}
            >
                <div className="card-contents">
                    <div className="image-container" style={{ backgroundImage: `url(${image})`}}/>
                    <div>
                        <div className="card-contents-title">
                            { title && <h3>{ title }</h3> }
                            { number && <h3>PROJECT @{ number }</h3> }
                        </div>
                        { rating && <Rating value={ rating } readOnly/> }
                        <h5>{ description }</h5>
                    </div>
                </div>
            </animated.div>
        </animated.div>
    );

}
