import tech1 from "../../../assets/images/technologies/spirit-notes-JVs1pY6DzZs-unsplash.jpg"
import tech2 from "../../../assets/images/technologies/dimitris-chapsoulas-eo5L4KQkNN4-unsplash.jpg"
import tech3 from "../../../assets/images/technologies/daniel-korpai-o1utz9Neufo-unsplash.jpg"
import tech4 from "../../../assets/images/technologies/niclas-illg-FJ5e_2f96h4-unsplash.jpg"
import tech5 from "../../../assets/images/technologies/steven-binotto-LtmZlM7rLfo-unsplash.jpg"

export const DATA = [
    {
        image: tech5,
        title: "Cost Reduction Solution",
        rating: 5,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        image: tech4,
        title: "Optimal Performance",
        rating: 5,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        image: tech3,
        title: "Micro-Cloud Service",
        rating: 5,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        image: tech2,
        title: "Modern UX/UI",
        rating: 5,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        image: tech1,
        title: "Artificial Intelligence",
        rating: 5,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
];