import React from "react";

export const useComponentWillMount = (func) => {
    const willMount = React.useRef(true);
    if (willMount.current) {
        func();
    }
    useComponentDidMount(() => {
        willMount.current = false;
    });
};

// eslint-disable-next-line
export const useComponentDidMount = (func) => React.useEffect(func, []);

// eslint-disable-next-line
export const useComponentDidUpdate = (func, variables) => React.useEffect(func, variables);

export const useComponentWillUnmount = (func) => React.useEffect(() => {
    return () => {
        func();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

export const useWindowSize = () => {
    const [ size, setSize ] = React.useState([0, 0]);

    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};
