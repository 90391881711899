import React from "react";

import VisibilitySensor from "react-visibility-sensor";
import Glitch from "../../components/glitch";

import bg from "../../../assets/images/background/pierpaolo-riondato-dYp0uwM1sgw-unsplash.jpg"

export default function Intro() {

    return (
        <section className="background" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="content-wrapper">
                <div className="content-title-header">
                    <div>Hello! We're</div>
                </div>
                <VisibilitySensor offset={{ top: 10 }} delayedCall>
                    {({ isVisible}) =>
                        <Glitch text={ "WiseBROs!" } isVisible={isVisible}/>
                    }
                </VisibilitySensor>
                <div className="content-subtitle-intro">
                    <div className="blob">
                        <i className="fas fa-angle-double-down fa-lg"/>
                    </div>
                    <div>Scroll down to explore!</div>
                </div>
            </div>
        </section>
    );

}