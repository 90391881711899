import React from "react";
import PropTypes from "prop-types";

import Baffle from "baffle-react";

function BaffleText({ text, isVisible, className }) {

    return (
        <span className={`${className ? className : ""}`}>
            <Baffle
                speed={50}
                obfuscate={!isVisible}
                update={isVisible}
                revealDuration={1000}
                revealDelay={1000}
            >
                { text }
            </Baffle>
        </span>
    );

}

BaffleText.propTypes = {
    text: PropTypes.string,
}

BaffleText.defaultProps = {
    text: "",
}

export default BaffleText;
