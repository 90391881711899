import React from "react";

import VisibilitySensor from "react-visibility-sensor";
import { isMobileOnly } from "react-device-detect";
import { useSprings } from "react-spring";
import { useGesture } from "@use-gesture/react";

import BaffleText from "../../components/baffle";
import Card from "../../components/card";
import { useWindowSize } from "../../utils/componentState";

import bg from "../../../assets/images/background/tony-hand-C9Ni6Gh_gWk-unsplash.jpg";
import { DATA } from "./data";

export default function Portfolios() {

    const [ width, height ] = useWindowSize();
    const col = (isMobileOnly ? 2 : 4);

    const x = i => isMobileOnly ? (width * 0.2 / col * (i - 2) + width * 0.05) : (width * 0.75 / col * (i - 2) + width * 0.1);
    const y = i => isMobileOnly ? i * -8 : i * -4;

    const to = (i) => ({ x: x(i), y: y(i), scale: 1, rot: -10 + Math.random() * 20, delay: (DATA[i].zIndex - 1) * 100, zIndex: DATA[i].zIndex });
    const appear = i => ({ x: x(i), y: y(i) });
    const fade = i => ({ x: x(i), y: -1000 });
    const trans = (r, s) => `perspective(1500px) rotateX(15deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

    const [ springs, api ] = useSprings(DATA.length, i => ({ ...to(i) }));

    const desktopBind = useGesture({
        onClick: e => {
        },
        onHover: ({ args: [index], hovering }) => {
            if (hovering) {
                api.start(i => {
                    if (index !== i) return;
                    return { scale: 1.1 };
                });
            } else {
                api.start(i => {
                    if (index !== i) return;
                    return { scale: 1 };
                });
            }
        }
    }, []);

    const mobileBind = useGesture({
        onClick: e => {
        }
    }, []);

    return (
        <section className="background" style={{ backgroundImage: "url(" + bg + ")" }}>
            <VisibilitySensor partialVisibility={true} offset={{ top: height / 2 }} minTopValue={ height / 2 } delayedCall>
                {({ isVisible}) => {
                    return (
                        <div className="content-wrapper">
                            <BaffleText text={"Portfolios"} isVisible={isVisible} className="content-title"/>
                            <div className="content-subtitle">
                                You can click below items.
                            </div>
                            <div className="card-container">
                                {
                                    springs.map(({ x, y, rot, scale }, i) => {
                                        return (
                                            <Card key={i} i={i} x={x} y={y} rot={rot} scale={scale} trans={trans}
                                                  zIndex={DATA[i].zIndex} delay={DATA[i].zIndex * 100}
                                                  data={DATA[i]}
                                                  appear={appear}
                                                  fade={fade}
                                                  desktopBind={desktopBind}
                                                  mobileBind={mobileBind}
                                                  isVisible={isVisible}
                                                  cursor={"pointer"}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                }}
            </VisibilitySensor>
        </section>
    );

}
