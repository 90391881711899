import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support

import React from "react";
import ReactDOM from "react-dom";

import App from "./js/App";
import reportWebVitals from "./js/utils/reportWebVitals";

import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/scss/index.scss";

ReactDOM.render(
    <App/>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
