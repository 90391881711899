import React from "react";

import VisibilitySensor from "react-visibility-sensor";
import { isDesktop } from "react-device-detect";
import { useSprings } from "react-spring";
import { useGesture } from "@use-gesture/react";

import BaffleText from "../../components/baffle";
import Card from "../../components/card";
import { useWindowSize } from "../../utils/componentState";

import bg from "../../../assets/images/background/ricardo-lopez-nebjAZknedw-unsplash.jpg";
import { DATA } from "./data";

export default function Technologies() {

    const [ width, height ] = useWindowSize();
    const [ faded ] = React.useState(() => new Set());

    // const x = i => (i % 2 === 0 ? -1 : 1) * width * i * 0.02;
    const x = _i => 0;
    const y = i => i * -4;

    const to = i => ({ x: x(i), y: y(i), scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 });
    const appear = i => ({ x: x(i), y: y(i) });
    const fade = i => ({ x: x(i), y: -1000 });
    const trans = (r, s) => `perspective(1500px) rotateX(15deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

    const [ springs, api ] = useSprings(DATA.length, i => ({ ...to(i) }));

    const desktopBind = useGesture({
        onDrag: ({ args: [index], active, movement: [mx], distance, direction: [xDir], velocity: [vx] }) => {
            const trigger = vx > 0.18;
            if (!active && trigger) faded.add(index);

            const dir = xDir < 0 ? -1 : 1;

            api.start(i => {
                if (index !== i) return;
                const isFaded = faded.has(index);
                const x = isFaded ? (100 + width) * dir : active ? mx : 0;
                const rot = mx / 100 + (isFaded ? dir * 10 * vx : 0);
                const scale = active ? 1.1 : 1;
                return { x, rot, scale, delay: undefined, config: { friction: 50, tension: vx ? 800 : isFaded ? 200 : 500 } };
            });

            if (!active && faded.size === DATA.length) {
                setTimeout(() => {
                    faded.clear();
                    api.start(i => to(i));
                }, 600);
            }
        },
        onHover: ({ args: [index], hovering }) => {
            if (hovering) {
                api.start(i => {
                    if (index !== i) return;
                    return { scale: 1.1 };
                });
            } else {
                api.start(i => {
                    if (index !== i) return;
                    return { scale: 1 };
                });
            }
        }
    }, []);

    const mobileBind = useGesture({
        onClick: e => {
            const index = e.args[0];
            const xDir = index % 2 === 0 ? -1 : 1;
            const mx = 100;
            const x = (200 + window.innerWidth) * xDir;
            const rot = mx / 100 + xDir * 10 * 0.2;
            const scale = 1.1;

            faded.add(index);
            api.start(i => {
                if (index !== i) return;
                const isFaded = faded.has(index);
                return { x, rot, scale, delay: undefined, config: { friction: 50, tension: isFaded ? 200 : 500 } };
            });

            if (faded.size === DATA.length) {
                setTimeout(() => {
                    faded.clear();
                    api.start(i => ({ ...to(i) }));
                }, 600);
            }
        }
    }, []);

    return (
        <section className="background" style={{ backgroundImage: "url(" + bg + ")" }}>
            <VisibilitySensor partialVisibility={true} offset={{ top: height / 2 }} minTopValue={ height / 2 } delayedCall>
                {({ isVisible}) => (
                    <div className="content-wrapper">
                        <BaffleText text={"Technologies"} isVisible={isVisible} className="content-title"/>
                        <div className="content-subtitle">
                            { isDesktop && <>You can drag below items.</> }
                            { !isDesktop && <>You can click below items.</> }
                        </div>
                        <div className="card-container">
                            {
                                springs.map(({ x, y, rot, scale }, i) => (
                                    <Card key={i} i={i} x={x} y={y} rot={rot} scale={scale} trans={trans}
                                          data={DATA[i]}
                                          appear={appear}
                                          fade={fade}
                                          desktopBind={desktopBind}
                                          mobileBind={mobileBind}
                                          isVisible={isVisible}
                                          cursor={"grab"}
                                    />
                                ))
                            }
                        </div>
                    </div>
                )}
            </VisibilitySensor>
        </section>
    );

}
