import React from "react";

import Layout from "./pages/layout";
import Intro from "./pages/intro";
import Technologies from "./pages/technologies";
import Portfolios from "./pages/portfolios";
import ContactUs from "./pages/contactus";

export default function App() {

    const appHeight = () => {
        const doc = document.getElementById("root");
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    }
    window.addEventListener("resize", appHeight);
    appHeight();

    return (
        <Layout>
            <Intro/>
            <Technologies/>
            <Portfolios/>
            <ContactUs/>
        </Layout>
    );

}