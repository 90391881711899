import { useRef, useEffect, useCallback } from "react";
import _ from "lodash";

export function useThrottle(cb, delay) {
    const options = { leading: true, trailing: false }; // pass custom lodash options
    const cbRef = useRef(cb);

    useEffect(() => {
        cbRef.current = cb;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        _.throttle((...args) => cbRef.current(...args), delay, options),
        [delay]
    );
}

export function useDebounce(cb, delay) {
    const options = { leading: false, trailing: true };
    const cbRef = useRef(cb);
    const isMounted = useIsMounted();

    useEffect(() => {
        cbRef.current = { cb, delay };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        _.debounce(
            (...args) => {
                // Don't execute callback, if (1) component in the meanwhile
                // has been unmounted or (2) delay has changed
                if (cbRef.current.delay === delay && isMounted()) {
                    cbRef.current.cb(...args);
                }
            },
            delay,
            options
        ),
        [delay, _.debounce]
    );
}

function useIsMounted() {
    const isMountedRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return () => isMountedRef.current;
}

