import React from "react";
import PropTypes from "prop-types";

import { useComponentDidMount } from "../utils/componentState";
import { useThrottle } from "../utils/lodash";
import { strReplace } from "../utils/strings";

const Layout = ({ children }) => {

    const isFirefox = (/Firefox/i.test(navigator.userAgent));
    const isIe = (/MSIE/i.test(navigator.userAgent)) || (/Trident.*rv:11\./i.test(navigator.userAgent));
    const scrollSensitivitySetting = 30; //Increase/decrease this number to change sensitivity to trackpad gestures (up = less sensitive; down = more sensitive)
    const slideDurationSetting = 600; //Amount of time for which slide is "locked"
    const totalSlideNumber = children.length;

    const [ ticking, setTicking ] = React.useState(false);
    const [ currentSlideNumber, setCurrentSlideNumber ] = React.useState(0);

    let yDown = null;

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches // browser API
        );
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!yDown) {
            return;
        }

        let yUp = evt.touches[0].clientY;
        let yDiff = yDown - yUp;

        if (yDiff > 0) {
            /* up swipe */
            setTicking(true);
            if (currentSlideNumber !== totalSlideNumber - 1) {
                setCurrentSlideNumber(currentSlideNumber + 1);
                nextItem(currentSlideNumber);
            }
            slideDurationTimeout(slideDurationSetting);
        } else {
            /* down swipe */
            setTicking(true);
            if (currentSlideNumber !== 0) {
                setCurrentSlideNumber(currentSlideNumber - 1);
                previousItem(currentSlideNumber - 1);
            }
            slideDurationTimeout(slideDurationSetting);
        }

        yDown = null;
    };

    // ------------- DETERMINE DELTA/SCROLL DIRECTION ------------- //
    function parallaxScroll(evt) {
        let delta;
        if (isFirefox) {
            //Set delta for Firefox
            delta = evt.detail * (-120);
        } else if (isIe) {
            //Set delta for IE
            delta = -evt.deltaY;
        } else {
            //Set delta for all other browsers
            delta = evt.wheelDelta;
        }

        if (ticking !== true) {
            if (delta <= -scrollSensitivitySetting) {
                //Down scroll
                setTicking(true);
                if (currentSlideNumber !== totalSlideNumber - 1) {
                    setCurrentSlideNumber(currentSlideNumber + 1);
                    nextItem(currentSlideNumber);
                }
                slideDurationTimeout(slideDurationSetting);
            }
            if (delta >= scrollSensitivitySetting) {
                //Up scroll
                setTicking(true);
                if (currentSlideNumber !== 0) {
                    setCurrentSlideNumber(currentSlideNumber - 1);
                    previousItem(currentSlideNumber - 1);
                }
                slideDurationTimeout(slideDurationSetting);
            }
        }
    }

    // ------------- SET TIMEOUT TO TEMPORARILY "LOCK" SLIDES ------------- //
    function slideDurationTimeout(slideDuration) {
        setTimeout(function() {
            setTicking(false);
        }, slideDuration);
    }

    // ------------- SLIDE MOTION ------------- //
    function nextItem(slideNumber) {
        const previousSlide = document.getElementsByClassName("background")[slideNumber];
        previousSlide.className = strReplace(previousSlide.className, " up-scroll", "") + " down-scroll";
    }

    function previousItem(slideNumber) {
        const currentSlide = document.getElementsByClassName("background")[slideNumber];
        currentSlide.className = strReplace(currentSlide.className, " down-scroll", "") + " up-scroll";
    }

    const throttleParallaxScroll = useThrottle(parallaxScroll, 100);

    const appHeight = () => {
        const doc = document.getElementsByClassName("background");
        if (doc && doc.length > 0) {
            for (let i = 0; i < doc.length; i++) {
                doc[i].style.setProperty('--app-height', `${window.innerHeight}px`);
            }
        }
    };
    appHeight();

    useComponentDidMount(() => {
        // ------------- ADD EVENT LISTENER ------------- //
        const mousewheelEvent = isFirefox ? "DOMMouseScroll" : "wheel";
        window.addEventListener(mousewheelEvent, throttleParallaxScroll, false);
        window.addEventListener("resize", appHeight);
        return function cleanup() {
            window.removeEventListener(mousewheelEvent, throttleParallaxScroll, false);
            window.removeEventListener("resize", appHeight, false);
        }
    });

    return (
        <>
            <div className="lock-landscape">
                Please rotate your device!
            </div>
            <div className="container"
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
            >
                { children }
            </div>
        </>
    );

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;
