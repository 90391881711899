import portfolio1 from "../../../assets/images/portfolios/alexander-shatov-mr4JG4SYOF8-unsplash.jpg"
import portfolio2 from "../../../assets/images/portfolios/alexander-shatov-fRjjnN_8njo-unsplash.jpg"
import portfolio3 from "../../../assets/images/portfolios/alexander-shatov-CTZhGbSxWLI-unsplash.jpg"
import portfolio4 from "../../../assets/images/portfolios/alexander-shatov-JlO3-oY5ZlQ-unsplash.jpg"

export const DATA = [
    {
        image: portfolio4,
        number: 2019,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        zIndex: 3,
    },
    {
        image: portfolio3,
        number: 2018,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        zIndex: 2,
    },
    {
        image: portfolio2,
        number: 2017,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        zIndex: 1,
    },
    {
        image: portfolio1,
        number: 2021,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        zIndex: 4,
    },
];