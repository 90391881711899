import React from "react";

import VisibilitySensor from "react-visibility-sensor";
import BaffleText from "../../components/baffle";

import { Box, FormControl, TextField, Button } from "@mui/material";
import { SendRounded } from "@mui/icons-material";

import { useWindowSize } from "../../utils/componentState";

import bg from "../../../assets/images/background/david-pisnoy-S_ao-YqXl2w-unsplash.jpg";

export default function ContactUs() {

    // eslint-disable-next-line no-unused-vars
    const [ width, height ] = useWindowSize();

    return (
        <section className="background" style={{ backgroundImage: "url(" + bg + ")" }}>
            <VisibilitySensor partialVisibility={true} offset={{ top: height / 2 }} minTopValue={height / 2} delayedCall>
                {({ isVisible}) =>
                    <div className="content-wrapper">
                        <BaffleText text={"Get in touch"} isVisible={isVisible} className="content-title"/>
                        <div>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="contactus">
                                <FormControl variant="standard">
                                    <TextField
                                        label="Name"
                                        defaultValue=""
                                        variant="standard"
                                    />
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField
                                        label="Email"
                                        defaultValue=""
                                        variant="standard"
                                    />
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField
                                        label="Messages"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        variant="standard"
                                    />
                                </FormControl>
                                <FormControl variant="standard">
                                    <Button variant="contained" startIcon={<SendRounded/>}>
                                        Send
                                    </Button>
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                }
            </VisibilitySensor>
        </section>
    );

}